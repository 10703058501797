import * as fb from '../../../api/firebase.js';
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { data } from 'jquery';

const state = {
    latestSessionsList: [],
    adminSessionsList: [],
    adminSessionUsersList: [],
    coachMessages: [],
    coachMessagesAdmin: [],
    coachMessagesForUser: [],
    coachMessagesForAdmin: [],
    coachListInfo: [],
    coachMessageCount: 0,
    firstVisibleAll: "",
    lastVisibleAll: "",
    next_all: false,
    prev_all: false,
    limitAll: 10,
    coachMessageUnsubscribe: null
}

const getters = {
    latestSessionsList: state => state.latestSessionsList,
    adminSessionsList: state => state.adminSessionsList,
    adminSessionUsersList: state => state.adminSessionUsersList,
    coachMessages: state => state.coachMessages,
    coachMessagesAdmin: state => state.coachMessagesAdmin,
    coachMessagesForUser: state => state.coachMessagesForUser,
    coachMessagesForAdmin: state => state.coachMessagesForAdmin,
    coachMessageCount: state => state.coachMessageCount,
    coachListInfo: state => state.coachListInfo,
    firstVisibleAll: state => state.firstVisibleAll,
    lastVisibleAll: state => state.lastVisibleAll,
    next_all: state => state.next_all,
    prev_all: state => state.prev_all,
    coachMessageUnsubscribe: state => state.coachMessageUnsubscribe
}

const mutations = {
    set_coachListener_unsubscribe(state, val) {
        state.coachMessageUnsubscribe = val;
    },
    reset_coachlist(state) {
        if (state.coachMessageUnsubscribe != null) {
        state.coachMessageUnsubscribe()
        }
    }
}

const actions = {
    addTopicsToUsers(context, status) {
        return new Promise(async (resolve, reject) => {
            // var updateOnlineCreds = fb.functions.httpsCallable("addUserTokensToTopics");
            // updateOnlineCreds()
            resolve()
        })
    },
    updateCoachOnlineStatus(context, status) {
        return new Promise(async (resolve, reject) => {
            var updateOnlineCreds = fb.functions.httpsCallable("updatecoachonlinestatus");
            updateOnlineCreds({
                status: status.status,
                uid: status.uid
            })
            resolve()
        })
    },
    updateCoachTestStatus(context, status) {
        return new Promise(async (resolve, reject) => {
            var updateTestCreds = fb.functions.httpsCallable("updatecoachteststatus");
            updateTestCreds({
                status: status.status,
                uid: status.uid
            })
            resolve()
        })
    },
    updateCoachOnlineSessionStatus(context, status) {
        return new Promise(async (resolve, reject) => {
            var updateOnlineCreds = fb.functions.httpsCallable("updatecoachonlinesessionstatus");
            updateOnlineCreds({
                status: status.status,
                uid: status.uid,
                sessionid: status.sessionid
            })
            resolve()
        })
    },
    updateCoachVisibleSessionStatus(context, status) {
        return new Promise(async (resolve, reject) => {
            var updateOnlineCreds = fb.functions.httpsCallable("updatecoachvisiblesessionstatus");
            updateOnlineCreds({
                status: status.status,
                uid: status.uid,
                sessionid: status.sessionid
            })
            resolve()
        })
    },
    updateCoachCalendarScheduleStatus(context, status) {
        return new Promise(async (resolve, reject) => {
            var updateOnlineCreds = fb.functions.httpsCallable("updatecoachcalendarschedulestatus");
            updateOnlineCreds({
                status: status.status,
                id: status.id
            })
            resolve()
        })
    },
    updateCoachCalendarOnlineStatus(context, status) {
        return new Promise(async (resolve, reject) => {
            var updateOnlineCreds = fb.functions.httpsCallable("updatecoachcalendaronlinestatus");
            updateOnlineCreds({
                status: status.status,
                id: status.id
            })
            resolve()
        })
    },
    updateCoachCalendarSchedules(context, status) {
        return new Promise(async (resolve, reject) => {
            var updateSchedule = fb.functions.httpsCallable("addspecificcoachsesssionstocalendarv2");
            updateSchedule({
                coachid: status.coach_list_id,
                month: status.month
            })
            resolve()
        })
    },
    resetLatestSessionsList(context, payload) {
        state.firstVisibleAll = "";
        state.lastVisibleAll = "";
        state.next_all = false;
        state.prev_all = false;
    },
    getAdminCoachSessionsList(context, payload) {
        fb.sessionsCollection
            .where("coach_list_id", "==", payload.coachid)
            .orderBy("datetime", "desc")
            .onSnapshot((querySnapshot) => {
                let sessionArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    sessionArray.push(data)
                })
                state.adminSessionsList = sessionArray;
            })
    },
    async addCoachMessage(context, payload) {
        let coachlistid = payload.coachlistid;
        fb.coachListCollection
            .doc(coachlistid)
            .collection("coachmessages")
            .add({
                coach: {
                    coachName: payload.coachname,
                    coachProfileImage: payload.coachprofileimage,
                    userid: payload.coachuserid
                },
                coachListId: payload.coachlistid,
                datetime: fb.firebase.firestore.FieldValue.serverTimestamp(),
                fromCoach: true,
                text: payload.text,
                user: {
                    userName: payload.username,
                    userProfileImage: payload.userprofileimage,
                    userid: payload.userid
                },
                userid: payload.userid
            }).then((docRef) => {
                var addUserMessage = fb.functions.httpsCallable("addcoachmessagetouserv2");
                addUserMessage({
                    coachlistid: payload.coachlistid,
                    userid: payload.userid,
                    username: payload.username,
                    userprofileimage: payload.userprofileimage,
                    coachname: payload.coachname,
                    coachprofileimage: payload.coachprofileimage,
                    coachuserid: payload.coachuserid,
                    text: payload.text
                })
            })
    },
    getCoachMessagesList(context, payload) {
        fb.coachMessagesCollection
            .where("coachListId", "==", payload.coachid)
            .onSnapshot((querySnapshot) => {
                let messagesArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    messagesArray.push(data)
                })
                state.coachMessages = messagesArray
            })
    },
    getCoachMessagesListDocument(context, payload) {
        fb.coachMessagesCollection
            .where("coachListId", "==", payload.coachid)
            .get()
            .then((querySnapshot) => {
                let messagesArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    messagesArray.push(data)
                })
                state.coachMessagesAdmin = messagesArray
            })
    },
    getCoachMessagesCount(context, payload) {
        fb.coachMessagesCollection
            .where("coachListId", "==", payload.coachid)
            .where("coachRead", "==", false)
            .onSnapshot((querySnapshot) => {
                let messagesArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    messagesArray.push(data)
                })
                state.coachMessageCount = messagesArray.length
            })
    },
    resetCoachMessagesForUser(context) {
        context.commit('reset_coachlist')
        state.coachMessagesForUser = []
    },
    getCoachMessageForUser(context, payload) {
        const unsubscribe = fb.coachListCollection
            .doc(payload.coachid)
            .collection("coachmessages")
            .where("coachListId", "==", payload.coachid)
            .where("userid", "==", payload.userid)
            .orderBy("datetime", "asc")
            .onSnapshot((querySnapshot) => {
                let messageArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    messageArray.push(data)
                })
                state.coachMessagesForUser = messageArray
                fb.coachMessagesCollection
                .where("coachListId", "==", payload.coachid)
                .where("userid", "==", payload.userid)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((subdoc) => {
                        subdoc.ref.update({
                            coachRead: true
                        })
                    })
                })
            })
        context.commit('set_coachListener_unsubscribe', unsubscribe)        
    },
    getCoachMessageForUserDocument(context, payload) {
        fb.coachListCollection
            .doc(payload.coachid)
            .collection("coachmessages")
            .where("coachListId", "==", payload.coachid)
            .where("userid", "==", payload.userid)
            .orderBy("datetime", "asc")
            .get()
            .then((querySnapshot) => {
                let messageArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    messageArray.push(data)
                })
                state.coachMessagesForAdmin = messageArray
            })        
    },
    getAdminSessionUsersList(context, payload) {
        fb.coachListCollection
            .doc(payload.coachid)
            .collection("sessions")
            .doc(payload.sessionid)
            .collection("session_users_history")
            .orderBy("startdatetime", "asc")
            .get()
            .then((querySnapshot) => {
                let userArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    userArray.push(data)
                })
                if (userArray.length == 0) {
                    fb.coachListCollection
                    .doc(payload.coachid)
                    .collection("sessions")
                    .doc(payload.sessionid)
                    .collection("session_users")
                    .orderBy("startdatetime", "asc")
                    .get()
                    .then((querySnapshot) => {
                        let userArray = []
                        querySnapshot.forEach((doc) => {
                            const data = doc.data()
                            data.id = doc.id
                            userArray.push(data)
                        })
                        state.adminSessionUsersList = userArray
                    })
                } else {
                    state.adminSessionUsersList = userArray
                }
            })        
    },
    getNextAdminLatestSessionsList(context, payload) {
        fb.sessionsCollection
            .orderBy("datetime", "desc")
            .startAfter(state.lastVisibleAll)
            .limit(state.limitAll)
            .get()
            .then((querySnapshot) => {
                state.lastVisibleAll = querySnapshot.docs[querySnapshot.docs.length - 1]
                state.firstVisibleAll = querySnapshot.docs[0];
                let sessionArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    sessionArray.push(data)
                })
                state.latestSessionsList = sessionArray;

                fb.sessionsCollection
                    .orderBy("datetime", "desc")
                    .startAfter(state.lastVisibleAll)
                    .limit(state.limitAll)
                    .get()
                    .then(snap => {
                        if (snap.size === 0) {
                            state.next_all = false
                            state.prev_all = true
                        } else {
                            state.next_all = true
                            state.prev_all = true
                        }
                        if (payload.firstTime) {
                            state.prev_all = false
                        }
                    })
            })
    },
    // updateFeedList(context, payload) {
    //     fb.feedCollection
    //     .get()
    //     .then((querySnapshot) => {
    //         querySnapshot.forEach((doc) => {
    //             doc.ref.update({
    //                 privatePost: false
    //             })
    //         })
    //     })
    // },
    getPrevAdminLatestSessionsList(context, payload) {
        fb.sessionsCollection
            .orderBy("datetime", "desc")
            .endBefore(state.firstVisibleAll)
            .limitToLast(state.limitAll)
            .get()
            .then((querySnapshot) => {
                state.lastVisibleAll = querySnapshot.docs[querySnapshot.docs.length - 1]
                state.firstVisibleAll = querySnapshot.docs[0];
                let sessionArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    sessionArray.push(data)
                })
                state.latestSessionsList = sessionArray;

                fb.sessionsCollection
                    .orderBy("datetime", "desc")
                    .endBefore(state.firstVisibleAll)
                    .limitToLast(state.limitAll)
                    .get()
                    .then(snap => {
                        if (snap.size === 0) {
                            state.next_all = true
                            state.prev_all = false
                        } else {
                            state.next_all = true
                            state.prev_all = true
                        }
                    })
            })
    },
    updateCoachPersonalInfo(context, form) {
        return new Promise(async (resolve, reject) => {
            let profileLink = ""
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < 8; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            if (form.profilephoto !== "" && form.profilephoto !== null) {
                let profilefirstPhoto = form.profilephoto.replace('data:image/jpeg;base64,', '')
                let profilenewPhoto = profilefirstPhoto.replace('data:image/png;base64,', '')
                var profileref = fb.storage.child('coaches/' + result + "-profile" + '.jpg')
                let profilesnapshot = await profileref.putString(profilenewPhoto, 'base64')
                profileLink = await profilesnapshot.ref.getDownloadURL()
            }

            let coachLink = ""
            if (form.coachphoto !== "" && form.coachphoto !== null) {
                let coachfirstPhoto = form.coachphoto.replace('data:image/jpeg;base64,', '')
                let coachnewPhoto = coachfirstPhoto.replace('data:image/png;base64,', '')
                var coachref = fb.storage.child('coaches/' + result + "-coach" + '.jpg')
                let coachsnapshot = await coachref.putString(coachnewPhoto, 'base64')
                coachLink = await coachsnapshot.ref.getDownloadURL()
            }

            let formData = {
                firstname: form.firstname,
                lastname: form.lastname,
                profilelink: profileLink,
                coachlink: coachLink,
                coachlistid: form.coachlistid,
                userid: form.userid
            }
            
            var updateCoach = fb.functions.httpsCallable("updatecoachpersonalinfo");
            updateCoach(formData)
            resolve("")
        });
    },
    updateCoachCommunity(context, form) {
        return new Promise(async (resolve, reject) => {
            let formData = {
                bio: form.bio,
                description: form.description,
                title: form.title,
                category: form.category,
                video: form.video,
                keywords: form.keywords,
                coachlistid: form.coachlistid,
                userid: form.userid
            }

            var updateCoach = fb.functions.httpsCallable("updatecoachcommunity");
            updateCoach(formData)
            resolve("")
        });
    },
    updateCoachSchedule(context, form) {
        return new Promise(async (resolve, reject) => {
            let formData = {
                livestreamtimes: form.livestreamtimes,
                livestreamday: form.livestreamday,
                livestreamhour: form.livestreamhour,
                livestreamminute: form.livestreamminute,
                livestreamweek: form.livestreamweek,
                coachlistid: form.coachlistid,
                userid: form.userid
            }

            var updateCoach = fb.functions.httpsCallable("updatecoachschedule");
            updateCoach(formData)
            resolve("")
        })
    },
    createNewCoach(context, form) {
        return new Promise(async (resolve, reject) => {
            let profileLink = ""
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < 8; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            if (form.profilephoto !== "" && form.profilephoto !== null) {
                let profilefirstPhoto = form.profilephoto.replace('data:image/jpeg;base64,', '')
                let profilenewPhoto = profilefirstPhoto.replace('data:image/png;base64,', '')
                var profileref = fb.storage.child('coaches/' + result + "-profile" + '.jpg')
                let profilesnapshot = await profileref.putString(profilenewPhoto, 'base64')
                profileLink = await profilesnapshot.ref.getDownloadURL()
            }

            let coachLink = ""
            if (form.coachphoto !== "" && form.coachphoto !== null) {
                let coachfirstPhoto = form.coachphoto.replace('data:image/jpeg;base64,', '')
                let coachnewPhoto = coachfirstPhoto.replace('data:image/png;base64,', '')
                var coachref = fb.storage.child('coaches/' + result + "-coach" + '.jpg')
                let coachsnapshot = await coachref.putString(coachnewPhoto, 'base64')
                coachLink = await coachsnapshot.ref.getDownloadURL()
            }

            let formData = {
                firstname: form.firstname,
                lastname: form.lastname,
                email: form.email,
                password: form.password,
                city: form.city,
                bio: form.bio,
                description: form.description,
                title: form.title,
                category: form.category,
                video: form.video,
                keywords: form.keywords,
                profilelink: profileLink,
                coachlink: coachLink,
                livestreamtimes: form.livestreamtimes,
                livestreamday: form.livestreamday,
                livestreamhour: form.livestreamhour,
                livestreamminute: form.livestreamminute,
                livestreamweek: form.livestreamweek,
                userid: ""
            }

            fb.usersCollection
                .where("email", "==", form.email.trim().toLowerCase())
                .get()
                .then((querySnapshot) => {
                    if (querySnapshot.size > 0) {
                        var isCoach = false;
                        var uid = "";
                        querySnapshot.forEach((doc) => {
                            let data = doc.data();
                            uid = data.user_id;
                            if (data.isCoach == true) {
                                isCoach = true;
                            }
                        })
                        if (isCoach) {
                            reject("That email is already linked to a coach account.")
                        } else {
                            formData.userid = uid;
                            var createExistingCoach = fb.functions.httpsCallable("createexistingcoach");
                            createExistingCoach(formData)
                            resolve("existing")
                        }
                    } else {
                        var createNewCoach = fb.functions.httpsCallable("createnewcoach");
                        createNewCoach(formData)
                        resolve("new")
                    }
                })
        });
    },
    async getCoachListInfo(context, payload) {
        let user = await fb.firebase.getCurrentUser()
        const coachListRef = fb.coachListCollection.where("coach.user_id", "==", user.uid).orderBy("lastUpdate", "desc").limit(1)
        const coachListSnap = await coachListRef.get()
        if (!coachListSnap.empty) {
            let coachList = []
            let subCoachList = []
            subCoachList = coachListSnap.docs[0].data()
            subCoachList['id'] = coachListSnap.docs[0].id
            coachList.push(subCoachList)
            state.coachListInfo = coachList
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
