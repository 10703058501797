import * as fb from '../../../api/firebase.js';
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import router from "@/router";

const state = {
    allNotifications: [],
    loadMoreLastAll: null,
    moreAllNotificationsPending: true,
    top5Topics: [],
    sessionTopics: [],
    notificationDetail: [],
    allNotificationsByTopic: [],
    loadMoreLastAllTopic: null,
    moreAllNotificationsTopicPending: true,
    allNotificationsByUser: [],
    loadMoreLastAllUser: null,
    moreAllNotificationsUserPending: true,
    latestPosts: [],
    latestUsers: [],
    latestTopics: [],
    notificationPosts: 0,
    notificationDiscussionId: null,
    allReplies: [],
    previousNotifications: []
}

const mutations = {
    ...vuexfireMutations,
    MERGE_ALL_NOTIFICATIONS_BATCH(state, payload) {
        state.allNotifications = state.allNotifications.concat(payload.notifyArray)
    },
    SET_LOADMORE_LAST_ALL(state, payload) {
        state.loadMoreLastAll = payload.lastVisible
    },
    ALL_NOTIFICATIONS_LOADED(state) {
        state.moreAllNotificationsPending = false
    },
    RESET_NOTIFICATION_ALL(state) {
        state.allNotifications = []
        state.loadMoreLastAll = null
        state.moreAllNotificationsPending = true
    },
    MERGE_ALL_NOTIFICATIONS_BATCH_TOPIC(state, payload) {
        state.allNotificationsByTopic = state.allNotificationsByTopic.concat(payload.notifyArray)
    },
    SET_LOADMORE_LAST_ALL_TOPIC(state, payload) {
        state.loadMoreLastAllTopic = payload.lastVisible
    },
    ALL_NOTIFICATIONS_LOADED_TOPIC(state) {
        state.moreAllNotificationsTopicPending = false
    },
    RESET_NOTIFICATION_ALL_TOPIC(state) {
        state.allNotificationsByTopic = []
        state.loadMoreLastAllTopic = null
        state.moreAllNotificationsTopicPending = true
    },
    MERGE_ALL_NOTIFICATIONS_BATCH_USER(state, payload) {
        state.allNotificationsByUser = state.allNotificationsByUser.concat(payload.notifyArray)
    },
    SET_LOADMORE_LAST_ALL_USER(state, payload) {
        state.loadMoreLastAllUser = payload.lastVisible
    },
    ALL_NOTIFICATIONS_LOADED_USER(state) {
        state.moreAllNotificationsUserPending = false
    },
    RESET_NOTIFICATION_ALL_USER(state) {
        state.allNotificationsByUser = []
        state.loadMoreLastAllUser = null
        state.moreAllNotificationsUserPending = true
    },
    TICK_NOTIFICATION_POSTS(state) {
        state.notificationPosts = state.notificationPosts + 1
    },
    RESET_NOTIFICATION_POSTS(state) {
        state.notificationPosts = 0
    },
    SET_NOTIFICATION_DISCUSSIONID(state, payload) {
        state.notificationDiscussionId = payload.discussid
    },
    RESET_NOTIFICATION_DISCUSSIONID(state) {
        state.notificationDiscussionId = null
    },
    RESET_REPLY_ALL(state) {
        state.allReplies = []
    }
}

const getters = {
    allNotifications: state => state.allNotifications,
    moreAllNotificationsPending: state => state.moreAllNotificationsPending,
    top5Topics: state => state.top5Topics,
    sessionTopics: state => state.sessionTopics,
    notificationDetail: state => state.notificationDetail,
    allNotificationsByTopic: state => state.allNotificationsByTopic,
    moreAllNotificationsTopicPending: state => state.moreAllNotificationsTopicPending,
    allNotificationsByUser: state => state.allNotificationsByUser,
    moreAllNotificationsUserPending: state => state.moreAllNotificationsUserPending,
    latestPosts: state => state.latestPosts,
    latestUsers: state => state.latestUsers,
    latestTopics: state => state.latestTopics,
    notificationPosts: state => state.notificationPosts,
    notificationDiscussionId: state => state.notificationDiscussionId,
    allReplies: state => state.allReplies,
    previousNotifications: state => state.previousNotifications
}

const actions = {
    async getPreviousNotifications(context, payload) {
        fb.generalNotifications.orderBy("timestamp", "desc")
        .onSnapshot((querySnapshot) => {
            let generalArray = []
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    data.id = doc.id
                    generalArray.push(data)
                })
                state.previousNotifications = generalArray;
        })
    },
    async getTop5Topics(context) {
        let user = await fb.firebase.getCurrentUser()
        fb.coachListCollection.where("coach.user_id", "==", user.uid).where("online", "==", true).orderBy("lastUpdate", "desc").limit(1).get()
            .then((querySnapshot) => {
                const coachListArray = querySnapshot.docs.map(doc => doc.id)
                fb.notifications.where("coach_list_id", "==", coachListArray[0]).where("online", "==", true)
                    .orderBy("commentCount", "desc")
                    .orderBy("datetime", "desc")
                    .limit(5)
                    .get()
                    .then((querySnapshot) => {
                        let sessions = []
                        querySnapshot.forEach((doc) => {
                            let subSessionsArray = []
                            subSessionsArray = doc.data()
                            subSessionsArray['id'] = doc.id
                            sessions.push(subSessionsArray)
                        })
                        state.top5Topics = sessions
                    })
            })
    },
    getLatestUsers: firestoreAction(async ({ bindFirestoreRef }) => {
        let user = await fb.firebase.getCurrentUser()
        fb.coachListCollection.where("coach.user_id", "==", user.uid).where("online", "==", true).orderBy("lastUpdate", "desc").limit(1).get()
            .then((querySnapshot) => {
                const coachListArray = querySnapshot.docs.map(doc => doc.id)
                return bindFirestoreRef('latestUsers', fb.coachSubscribe
                    .where("coach_list_id", "==", coachListArray[0])
                    .orderBy("lastUpdate", "desc")
                    .limit(10))
            })
    }),
    setNotificationsPosts(context) {
        context.commit('TICK_NOTIFICATION_POSTS')
    },
    resetNotificationPosts(context) {
        context.commit('RESET_NOTIFICATION_POSTS')
    },
    setNotificationDiscussionId(context, payload) {
        let discussid = payload.discussionId
        context.commit('SET_NOTIFICATION_DISCUSSIONID', { discussid })
    },
    resetNotificationDiscussionId(context) {
        context.commit('RESET_NOTIFICATION_DISCUSSIONID')
    },
    deleteComment(context, payload) {
        // var deleteComment = fb.functions.httpsCallable('deleteComment');
        // deleteComment({ discussionid: payload.discussionId, commentid: payload.commentId })
        //     .catch((error) => { })
    },
    deleteCommentFromTopic(context, payload) {
        // var deleteComment = fb.functions.httpsCallable('deleteComment');
        // deleteComment({ discussionid: payload.discussionId, commentid: payload.commentId })
        //     .catch((error) => { })
    },
    // deleteTopic(context, payload) {
    //     deleteTopic({ discussionid: payload.discussionId })
    //         .catch((error) => { })
    // },
    sendGeneralNotification(context, status) {
        return new Promise(async (resolve, reject) => {
            var updateOnlineCreds = fb.functions.httpsCallable("sendgeneralnotification");
            updateOnlineCreds({
                title: status.title,
                body: status.body,
                image: status.image
            })
            resolve()
        })
    },
    deleteReply(context, payload) {
        // var changeCount = fb.functions.httpsCallable('deleteReply');
        // changeCount({ discussionid: payload.discussionId, commentid: payload.commentId, replyid: payload.replyId })
        //     .catch((error) => { })
    },
    updateAllUsersLivestreamNotificationsOnServer(context, payload) {
        var updateNotify = fb.functions.httpsCallable("updateusernotifications");
        updateNotify({})
    },
    updateRecommendKeywordsIfDontHave(context, payload) {
        var updateNotify = fb.functions.httpsCallable("addkeywordstouserswithoutthem");
        updateNotify({})
    }

}

export default {
    state,
    mutations,
    getters,
    actions
}